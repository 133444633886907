.PannelloInfo {
  top: calc(var(--topbar-height) - 1px);
  padding-bottom: 20px;
  padding-top: 80px;
  width: 100%;
  background: #000000;
  position: fixed;
  left: 0;
  height: calc(100vh - var(--topbar-height) - 1px);
  right: 0;
  overflow-y: auto;
  z-index: 2000;
  animation: animationname 0.5s ease-out;
  transition: 0.5s ease-in-out;
}

@keyframes animationname {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.closing {
  animation: animationname2 0.5s ease-in;
  transition: 0.5s ease-in-out;
}

@keyframes animationname2 {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }

.PannelloInfo > div {
  font-size: 40px;
  letter-spacing: 0.8px;
  line-height: 48px;
  padding-left: 260px;
  padding-right: 260px;
}

.title-panel {
  color: rgb(217, 33, 0);
}

@media screen and (max-width: 1024px) {
  .PannelloInfo > div {
    padding-left: 100px;
    padding-right: 100px;
  }
}
