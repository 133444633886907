.block-left-spettacolo {
  border-right: 0.5px solid #555555;
  width: 20%;
  height: calc(100vh - var(--topbar-height));
  overflow: hidden;
}


@media screen and (max-width: 1200px){
    .block-left-spettacolo {
    width: 30%;
     }
}



.title-spettacolo {
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  font-size: 25px;
  line-height: 29px;
  color: #fcfcfc;
  padding: 24px;
  border-bottom: 0.5px solid #555555;
}

.description-spettacolo {
  padding-top: 21px;
  padding-bottom: 21px;
  font-size: 14px;
  line-height: 17px;
}

.more-info {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #fcfcfc;
}

.block-raggruppa {
  margin-top: 48px;
  font-size: 16px;
  line-height: 19px;
  padding-left: 24px;
  color: #fcfcfc;
}

.item-group {
  color: #686868;
  line-height: 19px;
  margin-top: 4px;
}

.item-group-active {
  color: #fcfcfc;
  line-height: 19px;
  margin-top: 4px;
}

.filters-spettacolo {
  margin-top: 48px;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-animation: fadein 0.4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.4s; /* Firefox < 16 */
  -ms-animation: fadein 0.4s; /* Internet Explorer */
  -o-animation: fadein 0.4s; /* Opera < 12.1 */
  animation: fadein 0.4s;
  height: 50vh;
  overflow: auto;
}

.filter-active {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #fcfcfc;
  text-transform: uppercase;
  margin-top: 6px;
}

.click-on-stack {
  margin-top: 60px;
  font-family: FormaDJRMicro;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: white;
  letter-spacing: 0.08em;
  padding-left: 24px;
  padding-right: 24px;
}

.buttons-actions {
  border-bottom: 0.5px solid #555555;
  display: flex;
}

.buttons-actions > a, .buttons-actions > div {
  width: 50%;
  padding: 25px 25px;

  color: white;
  font-family: FormaDJRMicro;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  cursor: pointer;
}

.buttons-actions > a{
    border-right: 0.5px solid #555555;
}

.buttons-actions > a:hover {
  text-decoration: none;
}
