.text-mobile {
  font-family: FormaDJRMicro;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #ffffff;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 55px;
  padding-bottom: 100px;
}

.top-mobile {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid white;
}
