@import 'styles/fonts.css';

body {
  background: black;
  color: white;
  font-family: FormaDJRMicro;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4em;
}

:root {
  --topbar-height: 58px;
}

/* ::-webkit-scrollbar{
  display: none;
} */

.pointer {
  cursor: pointer;
}

.text-red {
  color: #d92100;
}

.circle-filter {
  margin-top: 8px;
  border: 1px solid #d92100;
  border-radius: 16px;
  color: #d92100;
  font-size: 16px;
  line-height: 19px;
  padding-top: 7px;
  padding-bottom: 7px;
  text-align: center;
  padding-left: 18px;
  padding-right: 18px;
  width: fit-content;
}

.node-label {
  position: absolute;
  pointer-events: none;
  color: white;
  /* z-index: 10; */
}

.hover-node {
  opacity: 0.5;
}

.node {
  background-color: #00a2e8;
  width: 10px;
  height: 10px;
  position: absolute;
}

.medium-font {
  font-weight: medium;
}

.page {
  overflow: hidden;
  height: calc(100vh - 59px);
}

.input-forma {
  background: #000;
  width: 100%;
  border-bottom: 1px solid #fff;
  border-top: none;
  border-left: none;
  border-right: none;
  color: #fff;
}

.input-forma::placeholder {
  color: #6c757d;
}

.list-search-items,
.list-events-actors {
  list-style: none;
  padding: 0;
}

.list-events-actors li {
  font-size: 13px;
  color: #fff;
  letter-spacing: 0.03em;
  line-height: 1.4em;
  padding-bottom: 0.4em;
    padding-top: 0.4em;
}

.list-search-items li {
  padding-top: 7px;
  font-size: 15px;
  width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;
  color: #6c757d;

}

.actor-event-item {
  margin-right: 10px;

}

.relation-name {
  font-size: 17px;
  letter-spacing: 0.02em;
  color: #fff;
  text-decoration: underline;
}

.info-red-bottom {
  color: rgb(217, 33, 0);
  font-size: 14px;
  line-height: 17px;
  max-width: 540px;
}

.info-bottom {
  margin-top: 60px;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.img-catalog {
  transition: all 5s ease-in-out;
}

/* canvas {
  image-rendering: pixelated;
  image-rendering: crisp-edges;
  width: 100%;
  height: 100%;
} */
