.ReadMorePanel {
  top: calc(var(--topbar-height) - 1px);
  width: 100%;
  background: #000000;
  position: absolute;
  left: 0;
  height: calc(100vh - var(--topbar-height) - 1px);
  right: 0;
  overflow-y: auto;
  z-index: 2000;
  animation: animationname 0.5s ease-out;
  transition: 0.2s ease-in-out;
}

@keyframes animationname {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.closing {
  animation: animationname2 0.5s linear;
  transition: 0.5s ease-in-out;
}

@keyframes animationname2 {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }

.ReadMorePanel .mt-3, .ReadMorePanel .mt-5

{
font-size: 21px;
line-height: 123%;
letter-spacing: 0.07em;
}




.background-image-read-more {
  height: calc(100vh - var(--topbar-height) - 1px);
  width: 50%;
  background-size: cover;
  position: fixed;
  right: 0;
}

.background-image-read-more::after {
  background: rgb(217, 33, 0);
  mix-blend-mode: multiply;
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: background ease-out 0.5s;
}

.background-image-read-more:hover::after {
  background: none;
}

.close-read-more{
    position: fixed;
    right: 20px;
    top: 80px;
    cursor: pointer;
}
