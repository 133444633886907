.banner {
  margin-left: 60px;
  margin-right: 60px;
  padding-top: 54px;
}

.title-incommon-team {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.team-incommon {
  margin-top: 48px;
  padding-left: 52px;
  padding-bottom: 100px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.06em;
}

.logoAbout svg{
    fill: #fcfcfc;
}


.logoAbout{
    margin: 30px 60px 30px 60px;
}
