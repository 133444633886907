.item-spettacolo {
  height: 675px;
  width: 100%;
  background-size: cover;
}

.item-spettacolo::after {
  background: rgb(217, 33, 0);
  mix-blend-mode: multiply;
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: background ease-out 0.5s;
}

.item-spettacolo:hover::after {
  background: none;
}

.item-spettacolo:hover {
  box-shadow: none;
}

.link-spettacolo {
  color: #fcfcfc;
}

.link-spettacolo:hover {
  color: #fcfcfc;
  text-decoration: none;
}

.titolo-spettacolo {
  position: absolute;
  bottom: 20px;
  left: 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: var(--topbar-height);
  z-index: 2;
  width: 85%;
}

.info-documento {
  padding: 20px;
}

.num_documenti {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
  z-index: 2;
}

.data_documenti {
  font-size: 32px;
  line-height: 38px;
  color: #fcfcfc;
  z-index: 2;
}


@media screen and (max-width: 1024px){
  .titolo-spettacolo {

    font-size: 30px;
    line-height: 30px;
    max-width: 220px;
  }

}

@media screen and (max-width: 1024px){
  .data_documenti {

    font-size: 23px;
    line-height: 22.4px;

  }

}
