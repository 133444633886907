.menu-top {
  border-bottom: 0.5px solid #555555;
  line-height: 0px;
  height: calc(var(--topbar-height) - 1px);
  z-index: 1000;
  background: black;
}

.item-menu-top {
  border-right: 0.5px solid #555555;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: normal;
  background: -webkit-linear-gradient(360deg, #fcfcfc, #050500);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 27px;
  position: relative;
  display: inline-block;
}



.item-menu-top-1 {
  width: 10%;
  min-width: 10%;
  max-width: 10%;
  transition: min-width 0.8s ease-in, max-width 0.8s ease-out;

}

.item-menu-top-1:hover {
  width: 303px;
  min-width: 303px;
  max-width: 303px;
  background: -webkit-linear-gradient(360deg, #fcfcfc, #fcfcfc);
  background-position: left;
  -webkit-background-clip: text;

}

.item-menu-top-2 {
  width: 10%;
  min-width: 10%;
  max-width: 10%;
  transition: min-width 0.8s ease-in, max-width 0.8s ease-out;
}

.item-menu-top-2:hover {
  width: 217px;
  min-width: 217px;
  max-width: 217px;
  background: -webkit-linear-gradient(360deg, #fcfcfc, #fcfcfc);
  background-position: left;
  -webkit-background-clip: text;
}

.item-menu-top-3 {
  width: 10%;
  min-width: 10%;
  max-width: 10%;
  transition: min-width 0.8s ease-in, max-width 0.8s ease-out;
}

.item-menu-top-3:hover {
  width: 336px;
  min-width: 336px;
  max-width: 336px;
  background: -webkit-linear-gradient(360deg, #fcfcfc, #fcfcfc);
  background-position: left;
  -webkit-background-clip: text;
}

.item-menu-top-4 {
  width: 10%;
  min-width: 10%;
  max-width: 10%;
  transition: min-width 0.8s ease-in, max-width 0.8s ease-out;
}

.item-menu-top-4:hover {
  width: 246px;
  min-width: 246px;
  max-width: 246px;
  background: -webkit-linear-gradient(360deg, #fcfcfc, #fcfcfc);
  background-position: left;
  -webkit-background-clip: text;
}

.item-menu-top-5 {
  width: 10%;
  min-width: 10%;
  max-width: 10%;
  transition: min-width 0.8s ease-in, max-width 0.8s ease-out;
}

.item-menu-top-5:hover {
  width: 163px;
  min-width: 163px;
  max-width: 163px;
  background: -webkit-linear-gradient(360deg, #fcfcfc, #fcfcfc);
  background-position: left;
  -webkit-background-clip: text;
}

.item-menu-top-active {
  width: inherit !important;
  max-width: inherit !important;
  min-width: inherit !important;
  background: -webkit-linear-gradient(360deg, #fcfcfc, #fcfcfc);
  -webkit-background-clip: text;
}

.panel-info-icon {
  border-left: 0.5px solid #555555;
  height: 100%;
  display: flex;
  width: 70px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
