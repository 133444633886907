.TempiELuoghi {
  overflow-y: hidden;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #ccc;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}

.rc-slider-mark-text {
  color: #fff;
}

.mapboxgl-canvas {
  cursor: pointer;
  outline: none;
}

.year-right {
  font-family: FormaDJRMicro;
}
