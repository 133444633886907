.slideshow-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.slideshow-content {
  position: relative;
  flex: 1;
  overflow: hidden;
  user-select: none;
}

.slide-index {
  position: absolute;
  display: flex;
  align-items: center;
  right: 10px;
  top: 10px;
  z-index: 1000;
}

.slide-click-spy-prev {
  background: transparent;
  position: absolute;
  left: 0;
  right: 50%;
  top: 0;
  bottom: 0;
  z-index: 999;
}
.slide-click-spy-next {
  background: transparent;
  position: absolute;
  left: 50%;
  right: 5%;
  top: 0;
  bottom: 0;
  z-index: 999;
}

.open-document-file {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 100px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 0.02em;
  background-color: #c1c1c129;
}

.open-document-file:hover {
  background-color: black;
  transition: background-color 0.7s;
  text-decoration: none;
}
