.block-left-detail {
  background: #000000;
  width: 25%;
  border-right: 0.5px solid #555555;
}

.block-info-document {
  padding: 20px 24px 20px 24px;
  border-bottom: 0.5px solid #555555;
  overflow: auto;
  height: 40vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.block-info-document::-webkit-scrollbar {
  display: none;
}

.title-document-detail {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  padding-bottom: 18px;
}

.description-document-detail {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: #fff;
  padding-bottom: 16px;
  white-space: pre-wrap;
}

.document-creator {
  font-size: 11px;
  line-height: 13px;
}

.info-box {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #fff;
  padding-bottom: 23px;
}

.block-filters-detail {
  padding: 24px;
  padding-bottom: 50px;
  overflow: auto;
  height: calc(60vh - 57px);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.block-filters-detail::-webkit-scrollbar {
  display: none;
}

.label-filter-detail {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #fff;
}

.filter-body-detail {
  display: flex;
}

.filter-block-document {
  padding-bottom: 12px;
  color: #fff;
}

.filter-name-detail {
  font-size: 12px;
  letter-spacing: 0.3px;
  color: #ffffff;
  text-transform: uppercase;
}

.circle-filter-detail {
  border: 0.5px solid #ffffff;
  border-radius: 17px;
  font-size: 13px;
  text-align: center;
  width: max-content;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 2px;
  margin-top: 8px;
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 0.03em;
}
