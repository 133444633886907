.raggruppa-button {
  padding: 27px 15px 20px 15px;
  border-right: 0.5px solid #555555;
  border-bottom: 0.5px solid #555555;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
}


.count-documents {
  font-size: 18px;
  padding: 20px;
    letter-spacing: 0.04em;
display: none;
}



.reset-filtri {
  border-bottom: 0.5px solid #555555;
  padding: 26px 20px 20px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
      text-align: center;
}

.filters {
  font-size: 18px;
  padding: 24px;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.filters::-webkit-scrollbar{
  display: none;
}

.search-filter {
  padding: 15px;
  width: 100%;
  border-bottom: 0.5px solid #555555;
}

.filters-active {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  align-content: flex-start;
  border-bottom: 0.5px solid #555555;
  min-height: 70px;
}

.filters-active::-webkit-scrollbar{
  display: none;
}


.filters-active > span {
  border: 1px solid #fff;
  height: 22px;
  cursor: pointer;
  font-size: 13px;
  letter-spacing: 0.03em;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.block-filters{
  width: 25%;
  border-right: 0.5px solid #555555;
  padding-bottom: 30px;
}

.block-catalogo {
  width: 75%;
  overflow: auto;
  align-content: flex-start;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.block-catalogo::-webkit-scrollbar{
  display: none;
}

.search-input{
  background-color: #000;
  border: none;
  color: #FFF;
}

input::placeholder{
  color: #FFF;
}

input:focus {
  outline: none !important;
}

.w-55{
  width: 55%;
}

.w-45{
  width: 45%;
}



@media screen and (max-width: 1330px){
    .raggruppa-button, .reset-filtri{

      font-size: 10px;
     }
}
