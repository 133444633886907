.DetailLuogo {
  width: 25%;
  position: absolute;
  height: 100%;
  left: 0px;
  top: var(--topbar-height);
  border-right: 0.5px solid #555555;
  background-color: #000;
  padding-bottom: 100px;
  z-index: 10;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  animation: animationTranslate 0.5s linear;
  /*animation: animation-name animation-duration animation-direction animation-iteration-count */
  transition: 0.2s ease-in-out;
}

@keyframes animationTranslate {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.closingDetail {
  animation: animationTranslateClose 0.5s linear;
  /*animation: animation-name animation-duration animation-direction animation-iteration-count */
  transition: 0.2s ease-in-out;
}

@keyframes animationTranslateClose {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


::-webkit-scrollbar {
  display: none;
}
