.TopHome {
  padding-top: 30px;
  padding-left: 61px;
  padding-right: 61px;
  padding-bottom: 10px;
}

.block-text-home {
  padding: 20px 60px 20px 60px;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.018em;
  color: #fff;
  column-count: 2;
}

.HomePages {
  margin-top: 32px;
}

.page-home {
  border-top: 1px solid #ffffff;
  display: flex;
  height: 110px;

  /* align-items: center; */
  cursor: pointer;
  overflow: hidden;
  transition: height ease-out 0.5s;
}

.page-home-open {
  border-top: 1px solid #ffffff;
  display: flex;
  cursor: pointer;
  height: 400px;
  transition: height ease-out 0.5s;
}

.last-item-menu {
  border-bottom: 1px solid #ffffff;
}

.page-home-open > .text-page-home {
  padding-left: 61px;
  width: 50%;
  padding-right: 5%;
  background-color: black;
  border-right: white 1px solid;
}

.page-home > .text-page-home {
  padding-left: 61px;
  width: 50%;
  background-color: black;
}

.image-block {
  width: 50%;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.title-page-home {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.03em;
  padding-top: 30px;
}

.esplora-home {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 43px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin-top: 56px;
}

.esplora-home + img {
  width: 20px;
}

.esplora-home:hover {
  color: #ffffff;
  margin-top: 16px;
  text-decoration: none !important;
}

.esplora-link {
  margin-right: 82%;
  display: inline-block !important;
  color: white;
  text-decoration: none;
}

.esplora-link::after {
  content: '';
  display: block !important;
  width: 0;
  height: 1px;
  background: white;
  transition: width 0.3s;
}

.esplora-link:hover::after {
  width: 100%;
}

.description-page-home {
  padding-top: 16px;
  padding-bottom: 32px;
  font-size: 27px;
  line-height: 37px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.03em;
}

.first-circle {
  transform: translateY(-10px);
  transition: all 0.7s ease-in-out;
}

.second-circle {
  transform: translateY(10px);
  transition: all 0.7s ease-in-out;
}

.ContainerSvg {
  position: relative;
  margin: 30px 60px 30px 60px;
}

.ContainerSvg svg {
  fill: #fcfcfc;
}

.footer {
  padding-top: 76px;
  padding-bottom: 20px;
  padding-left: 61px;
  padding-right: 61px;
  background-color: black;
}

.label-footer {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.st0 {
  fill: #ffffff;
}
.st1 {
  /* fill-rule: evenodd;
  clip-rule: evenodd; */
  fill: #ffffff;
}

.text-project {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.05em;
  width: 260px;
}

.copyright-text-footer {
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.04em;
}

.ml-30 {
  margin-left: 30px;
}

@media screen and (max-width: 1200px) {
  .block-text-home {
    padding-top: 3px;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.03em;
  }
}

@media screen and (max-width: 1200px) {
  .title-page-home {
    font-size: 28px;
    line-height: 28px;
    padding-top: 35px;
    letter-spacing: 0.03em;
  }
}

@media screen and (max-width: 1200px) {
  .description-page-home {
    font-size: 20px;
    line-height: 28px;
    padding-top: 35px;
    letter-spacing: 0.04em;
  }
}

@media screen and (max-width: 1200px) {
  .esplora-home {
    font-size: 20px;
    line-height: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .text-page-home img {
    width: 19px;
  }
}

@media screen and (max-width: 1200px) {
  .page-home-open {
    height: 380px;
  }
}


.modal-body{
padding:0px;
border: 0px;

}

.modal-content{
padding:0px;
border: 0px;
background-color: black;
width: auto;
}

.modal-dialog{
  width: 700px;
  margin: 0 auto;
  max-width: none;
}
