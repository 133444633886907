.body-document{
  width: 75%;
  overflow: hidden;
  height: calc(100vh - 59px);
}

.header-images{
  padding: 20px;
  border-bottom: 0.5px solid #555555;
}

.filter-block{
  padding-bottom: 24px;
  color: #000000;
}

.zoom-buttons{
  color: #686868;
}

.reset:hover{
  color: #FFF !important;
}

.img-document{
  width: 100%;
  height: calc(100vh - 120px);
}

.img-document::-webkit-scrollbar{
  display: none;
}

/* .TransformComponent-module_container__3NwNd {
  height: 100% !important;
  width: 100% !important; 
}

.TransformComponent-module_content__TZU5O {
  height: 70% !important;
  width: 100% !important; 
  
} */


