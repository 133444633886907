@font-face {
  src: url('fonts/FormaDJRMicro-Regular.eot'); /* IE < 9 */
  src: url('fonts/FormaDJRMicro-Regular.eot?#') format("embedded-opentype"), /* IE 9 */
       url('fonts/FormaDJRMicro-Regular.woff2') format("woff2"),
       url('fonts/FormaDJRMicro-Regular.woff') format("woff");
  font-family: 'FormaDJRMicro';
  font-style: normal;
  font-weight: normal;
}

@font-face {
    src: url('fonts/FormaDJRMicro-Italic.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRMicro-Italic.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRMicro-Italic.woff2') format("woff2"),
         url('fonts/FormaDJRMicro-Italic.woff') format("woff");
    font-family: 'FormaDJRMicro';
    font-style: italic;
    font-weight: normal;
    }

@font-face {
    src: url('fonts/FormaDJRMicro-Bold.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRMicro-Bold.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRMicro-Bold.woff2') format("woff2"),
         url('fonts/FormaDJRMicro-Bold.woff') format("woff");
    font-family: 'FormaDJRMicro';
    font-style: normal;
    font-weight: bold;
    }

@font-face {
    src: url('fonts/FormaDJRMicro-BoldItalic.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRMicro-BoldItalic.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRMicro-BoldItalic.woff2') format("woff2"),
         url('fonts/FormaDJRMicro-BoldItalic.woff') format("woff");
    font-family: 'FormaDJRMicro';
    font-style: italic;
    font-weight: bold;
    }

@font-face {
    src: url('fonts/FormaDJRText-Regular.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRText-Regular.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRText-Regular.woff2') format("woff2"),
         url('fonts/FormaDJRText-Regular.woff') format("woff");
    font-family: 'FormaDJRText';
    font-style: normal;
    font-weight: normal;
    }

@font-face {
    src: url('fonts/FormaDJRText-Italic.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRText-Italic.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRText-Italic.woff2') format("woff2"),
         url('fonts/FormaDJRText-Italic.woff') format("woff");
    font-family: 'FormaDJRText';
    font-style: italic;
    font-weight: normal;
    }

@font-face {
    src: url('fonts/FormaDJRText-Bold.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRText-Bold.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRText-Bold.woff2') format("woff2"),
         url('fonts/FormaDJRText-Bold.woff') format("woff");
    font-family: 'FormaDJRText';
    font-style: normal;
    font-weight: bold;
    }

@font-face {
    src: url('fonts/FormaDJRText-BoldItalic.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRText-BoldItalic.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRText-BoldItalic.woff2') format("woff2"),
         url('fonts/FormaDJRText-BoldItalic.woff') format("woff");
    font-family: 'FormaDJRText';
    font-style: italic;
    font-weight: bold;
    }

@font-face {
    src: url('fonts/FormaDJRDeck-Regular.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRDeck-Regular.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRDeck-Regular.woff2') format("woff2"),
         url('fonts/FormaDJRDeck-Regular.woff') format("woff");
    font-family: 'FormaDJRDeck';
    font-style: normal;
    font-weight: normal;
    }

@font-face {
    src: url('fonts/FormaDJRDeck-Italic.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRDeck-Italic.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRDeck-Italic.woff2') format("woff2"),
         url('fonts/FormaDJRDeck-Italic.woff') format("woff");
    font-family: 'FormaDJRDeck';
    font-style: italic;
    font-weight: normal;
    }

@font-face {
    src: url('fonts/FormaDJRDeck-Bold.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRDeck-Bold.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRDeck-Bold.woff2') format("woff2"),
         url('fonts/FormaDJRDeck-Bold.woff') format("woff");
    font-family: 'FormaDJRDeck';
    font-style: normal;
    font-weight: bold;
    }

@font-face {
    src: url('fonts/FormaDJRDeck-BoldItalic.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRDeck-BoldItalic.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRDeck-BoldItalic.woff2') format("woff2"),
         url('fonts/FormaDJRDeck-BoldItalic.woff') format("woff");
    font-family: 'FormaDJRDeck';
    font-style: italic;
    font-weight: bold;
    }

@font-face {
    src: url('fonts/FormaDJRDisplay-Regular.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRDisplay-Regular.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRDisplay-Regular.woff2') format("woff2"),
         url('fonts/FormaDJRDisplay-Regular.woff') format("woff");
    font-family: 'FormaDJRDisplay';
    font-style: normal;
    font-weight: normal;
    }

@font-face {
    src: url('fonts/FormaDJRDisplay-Italic.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRDisplay-Italic.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRDisplay-Italic.woff2') format("woff2"),
         url('fonts/FormaDJRDisplay-Italic.woff') format("woff");
    font-family: 'FormaDJRDisplay';
    font-style: italic;
    font-weight: normal;
    }

@font-face {
    src: url('fonts/FormaDJRDisplay-Bold.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRDisplay-Bold.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRDisplay-Bold.woff2') format("woff2"),
         url('fonts/FormaDJRDisplay-Bold.woff') format("woff");
    font-family: 'FormaDJRDisplay';
    font-style: normal;
    font-weight: bold;
    }

@font-face {
    src: url('fonts/FormaDJRDisplay-BoldItalic.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRDisplay-BoldItalic.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRDisplay-BoldItalic.woff2') format("woff2"),
         url('fonts/FormaDJRDisplay-BoldItalic.woff') format("woff");
    font-family: 'FormaDJRDisplay';
    font-style: italic;
    font-weight: bold;
    }

@font-face {
    src: url('fonts/FormaDJRBanner-Regular.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRBanner-Regular.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRBanner-Regular.woff2') format("woff2"),
         url('fonts/FormaDJRBanner-Regular.woff') format("woff");
    font-family: 'FormaDJRBanner';
    font-style: normal;
    font-weight: normal;
    }

@font-face {
    src: url('fonts/FormaDJRBanner-Italic.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRBanner-Italic.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRBanner-Italic.woff2') format("woff2"),
         url('fonts/FormaDJRBanner-Italic.woff') format("woff");
    font-family: 'FormaDJRBanner';
    font-style: italic;
    font-weight: normal;
    }

@font-face {
    src: url('fonts/FormaDJRBanner-Bold.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRBanner-Bold.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRBanner-Bold.woff2') format("woff2"),
         url('fonts/FormaDJRBanner-Bold.woff') format("woff");
    font-family: 'FormaDJRBanner';
    font-style: normal;
    font-weight: bold;
    }

@font-face {
    src: url('fonts/FormaDJRBanner-BoldItalic.eot'); /* IE < 9 */
    src: url('fonts/FormaDJRBanner-BoldItalic.eot?#') format("embedded-opentype"), /* IE 9 */
         url('fonts/FormaDJRBanner-BoldItalic.woff2') format("woff2"),
         url('fonts/FormaDJRBanner-BoldItalic.woff') format("woff");
    font-family: 'FormaDJRBanner';
    font-style: italic;
    font-weight: bold;
    }
