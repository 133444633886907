.react-datepicker__month{
  background-color: black;
  padding: 0.4rem;
  margin: 0;
}

.react-datepicker__day{
  color: #F5f5f5;
}

.react-datepicker__day:hover{
  color: black;
}

.react-datepicker__header{
  background: black;
  border-bottom: 0;
}

.react-datepicker__current-month{
  color: #F5f5f5;
}

.react-datepicker__day-name{
  color: #F5f5f5;
}

.react-datepicker__day--selected, .react-datepicker__day--selected:hover{
  background: #f5f5f5;
  color: black;
}

.filters{
  font-size: 18px;
}

.search-filter{
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #555555;
}

.item-filter{
  padding: 10px;
  width: 100%;
  border-bottom: 0.5px solid #555555;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.micro-filters{
  margin-top: 20px;
  font-size: 15px;
  padding: 10px;
  width: 100%;
  -webkit-animation: fadein 0.4s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.4s; /* Firefox < 16 */
        -ms-animation: fadein 0.4s; /* Internet Explorer */
         -o-animation: fadein 0.4s; /* Opera < 12.1 */
            animation: fadein 0.4s;
}

.item-micro-filter{
  margin-bottom: 10px;
}

.name-micro-filter{
  width: 200px;
}

/* per nascondere i numeri
.name-micro-filter + div{
  display: none!important;
}
*/


.item-micro-filter:hover:before
{
    content: "+";
    font-size: 15px;
    margin-right: 5px;
}

.rotate-show-hide {
  transform: rotate(180deg);
}
